import React from "react";
import { Link } from "react-router-dom";
import Triangles from "../extraImages/triangles.svg";
import "./Home.css";
import "./scripts/typewriter";
const Home = () => {
  return (
    <section id="home">
      <div className="homeContainer">
        <h1>Shawn Morreau</h1>
        <h2 className="role">Software Engineer</h2>
        <div className="cta">
          <h3>Let's work together.</h3>
          <Link to="/contact/" className="ctaButton"><button>Hire Me</button></Link>
        </div>
      </div>
      <img className="triangles" src={Triangles} alt="" />
    </section>
  );
};

export default Home;
