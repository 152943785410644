import React from "react";
import { Link } from "react-router-dom";
import ProfilePic from "../extraImages/shawnPic.png";
import Icons from "../extraImages/weights bike doggo.svg"
import "./About.css";
const About = () => {
  return (
    <section className="about">
      <div className="aboutContainer">
        <>
          <img src={ProfilePic} alt="" />
        </>
        <section className="textContainer">
          <h1>Hi,</h1>
          <p>
            I have a passion for learning new frameworks, libraries and languages to be able to create a wide variety of content. I also enjoy practicing coding challenges on Leetcode so that I can continue testing my problem solving skills.
            <br />
            <br />
            Through my degree at Carleton University, I learned Java, Python, C and Racket with a main focus on Java, OOP practices, design patterns, data structures, architecture, documenting code, UML diagrams, and working in group projects. On my own through courses, tutorials and documentation, I learned Javascript, React, HTML/CSS, Ruby, MongoDB, Express and libraries to use in conjunction with programs/web apps.
          </p>
          <img className="hobbies" src={Icons} alt="" />
          <Link to="/contact/" className="connectCTA"><button>Let's connect</button></Link>
        </section>
      </div>
    </section>
  );
};

export default About;
