import React, { useState } from "react";
import projectList from "./Projects.json";
import modalDetails from "./modalInfo.json";
import ProjectModal from "./ProjectModal/ProjectModal";
import "./Projects.css";

const Projects = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState()

  const handleModal = (details) => {
    setModalOpen(true);
    setModalProps(modalDetails.projects[0][details]);
  }
  const clearModal = () => {
    setModalOpen(false);
    setModalProps(null);
  }
  const projects = projectList.projects.map((project, i) => (
    <li key={i} id={project.setID}>
      <button
        id="viewProject"
        onClick={() => handleModal(project.key)}
      >View Project</button>
    </li>
  ));

  return (
    <section className="projects">
      {modalOpen ? (
        <ProjectModal
          modalProps={modalProps}
          removeModal={clearModal}          
        />
      ) : (
        <section className="outerContainer">
          <h1>Projects</h1>
          <ul className="projectsContainer">{projects}</ul>
        </section>
      )}
    </section>
  );
};
export default Projects;
