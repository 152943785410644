import React from "react";
import "./Skills.css";
const Skill = (props) => {
  const { skillTitle, skills, found } = props;
  const skillList = skills[0].map((skill, i) =>
    <li
      key={`${skill["name"]} - ${i}`}
      className={found.includes(skill['name'].toLowerCase().replace(" ", "").replace(".", "")) ? 'strike' : ''}
    >
      {skill["name"]}
    </li>);
  return (
    <div className="skillGrouping">
      <h2 className="titleSkills">{skillTitle}</h2>
      <ul className="skillsList">{skillList}</ul>
    </div>
  );
};
export default Skill;
