import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import SocialIcon from "../../SocialMedia/SocialIcon";
import "../../SocialMedia/Styling/socialMedia.css";
import "./ProjectModal.css";

const ProjectModal = (props) => {
  console.log(props.modalProps);
  const { title, desc, link, liveLink, skills } = props.modalProps || "";
  const wrapperRef = useRef(null);
  const outsideRefAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(e) {
        if (ref.current && !ref.current.contains(e.target)) {
          props.removeModal();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };
  outsideRefAlerter(wrapperRef);
  const skillsUsed = skills.map((skill, i) => <li key={i}>{skill}</li>);
  return (
    <div className="modal">
      <div className="modalContainer" ref={wrapperRef}>
        <h1 className="modalTitle">{title}</h1>
        <p className="modalContent">{desc}</p>
        <ul className="modalSocials">
          {link && <SocialIcon
            url={link}
            icon={<FontAwesomeIcon icon={faGithub} />}
            className="git"
          />}
          {liveLink && <SocialIcon
            url={liveLink}
            icon={<FontAwesomeIcon icon={faLink} />}
          />}

        </ul>

        <ul>{skillsUsed}</ul>
      </div>
    </div>
  );
};
export default ProjectModal;
