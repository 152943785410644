const letters =
  "AEJASMINEGHSZBADCQCJMWSKDYZGTYEONNQNESAORQBPJDCRJNZJJHCISVNOLYSKDIAAXDUEGXXSAGTUYFIHMVVDNCNYDSCSOCFYUSXHIAIQJOLGPSCDIREACTXSTMUVDBPRSRBACVPFCKCNGKOEEGIFIFYUHTMLYIGITMJAYNQPPVJRKZNOHHPMDSNHGYTDXABMNHOBFCDMYVMTEBAYRPFU"
const wordBank = [
  "java",
  "javascript",
  "mongodb",
  "sql",
  "travisci",
  "react",
  "spring",
  "junit",
  "jasmine",
  "html",
  "css",
  "nodejs",
  "git",
];
const colors = [
    "#4599e3"
]
const wordSearches=[
  "RFDYDEMHCJNPNNJUBDAREACTOHFAAJWNOWADQLDXTDNJAVASURDBFFQZXKRRGVWAQEMNQYZBZHTMLHOQYSCZOIIDLGNXGCSGDYFCGUHQNTEIOBDRWCBSPRINGRGEMPDFEZTLXZDIOOFSTIWQEDDJFCDMRPXKGOUDTDJJPGZRGUTTPGITRJQDSTECSSNNKSQLZPMNFICGNQBXDHPTRAVISCIV",
  // "AEJASMINEGHSZBADCQCJMWSKDYZGTYEONNQNESAORQBPJDCRJNZJJHCISVNOLYSKDIAAXDUEGXXSAGTUYFIHMVVDNCNYDSCSOCFYUSXHIAIQJOLGPSCDIREACTXSTMUVDBPRSRBACVPFCKCNGKOEEGIFIFYUHTMLYIGITMJAYNQPPVJRKZNOHHPMDSNHGYTDXABMNHOBFCDMYVMTEBAYRPFU",
  "SQLJHIDXNODEJSSZDCPXZPAMONGODBWPSZCHNKSFQSKJAVASCRIPTGZRPUMZMPHYVCSKEYTPXURVXSZIJTRTGNMKHLZHIJSVDUNZRIMKQCMHPFNJIJXILEWUQKGSIQDEGUQDPSETDAJNISWSFMPNTRAVISCIYWTNFILZFIHJHGVSBSWEHXGOFKATJAVADJIIIBYXBTFILNHTMLFYTREACTEG",
  "REACTVXSTTNQAUYCJQHZJXSXQCPMONGODBPAACAWGHFJWRHVKGLNNJABVINTTSXUIIDIPOVYKXAZURXMKNJNWTODSDXISSMAPELJCAGBIEGGSOCRGVEJKUDCSIZJFOSJRKPIWLUNGVOMKSECQAIJTSSUJIFCSSITXNCVPVPCUBGTQHASVNXKIATKOIQQZOPNRNQMESSPWSQLKUAPERDMFENF"
]
export {
    wordSearches,
    letters,
    wordBank,
    colors
}