import React, { useState } from "react";
import SkillsList from "./Skills.json";
import Skill from "./Skill";
import "./Skills.css";
import "./celebrate/celebrate.css"

import { wordBank, colors, wordSearches } from "./helpers/helpers.js";
import Celebrate from "./celebrate/Celebrate";
const NUM_COL = 18;
const randNum = Math.floor(Math.random() * (wordSearches.length))
const selectedWordSearch = wordSearches[randNum]
const Skills = () => {
  const [highlight, setHighlight] = useState(false);
  const [selectedLetters, setSelectedLetters] = useState("");
  const [letterRefs, setLetterRefs] = useState([]);
  const [foundWords, setFoundWords] = useState([]);
  const [winner, setWinner] = useState(false)
  const letterClicked = (e) => {
    setHighlight(true);
    addHighlightStyle(e.target);
    setSelectedLetters(e.target.innerHTML.trim());
    setLetterRefs([e.target]);
  };
  const highlightLetter = (e) => {
    if (highlight) {
      addLetter(e.target.innerHTML.trim());
      setLetterRefs([...letterRefs, e.target]);
      addHighlightStyle(e.target);
    }
  };
  const addLetter = (letter) => {
    const updated = selectedLetters + letter;
    setSelectedLetters(updated);
  };
  const addHighlightStyle = (target) => {
    target.style.backgroundColor = colors[0];
  };
  const mouseReleased = (e) => {
    setHighlight(false);
    checkSelectedForWords();
  };
  const outOfBounds = () => {
    setHighlight(false);
    checkSelectedForWords();
  };
  const cleanState = () => {
    setSelectedLetters("");
    setLetterRefs([]);
  };
  const checkForWinner = () => {
    if (foundWords.length === wordBank.length) {
      setWinner(true);
      setTimeout(() => {
        setWinner(false);
        foundWords.length += 1;
      }, 7000)
    }
  }
  const checkSelectedForWords = () => {
    if (wordBank.includes(selectedLetters.toLowerCase())) {
      letterRefs.forEach((ref) => {
        ref.dataset.solved = "true";
        ref.style.backgroundColor = "#d0a";
      });
      const found = [...foundWords, selectedLetters.toLowerCase()]
      setFoundWords(found)
    } else {
      for (const ref of letterRefs) {
        if (ref.dataset.solved === "false") {
          ref.style.backgroundColor = "";
        } else {
          ref.style.backgroundColor = "#d0a";
        }
      }
    }
    cleanState();
    checkForWinner();
  };

  const letterButtons = selectedWordSearch.split("").map((letter, idx) => (
    <span key={idx}>
      <button
        id="letter"
        onMouseEnter={highlightLetter}
        onMouseDown={letterClicked}
        data-solved="false"
      >
        {letter}
      </button>
      {(idx + 1) % NUM_COL === 0 && <br />}
    </span>
  ));
  return (
    <div className="skills">
      <h1 id="skillTitle">Skills</h1>
      <Celebrate winner={winner} />
      <div className={"wordSearch " + (winner ? "hide" : "")}>
        <div className="innerContainer">
          <div className="wordSearchContainer" >
            <div className="wordSearchGrid" onMouseUp={mouseReleased} onMouseLeave={outOfBounds}>
              {letterButtons}
            </div>
          </div>
          <div className="skillGroupContainer">
            {SkillsList.skills.map((skill, i) => (
              <Skill
                skillTitle={Object.keys(skill)[0]}
                skills={Object.values(skill)}
                key={i}
                className="skillGroupContainer"
                found={foundWords}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Skills;
