import React from "react"
import "./celebrate.css"
import Confetti from "../../extraImages/confetti1.gif";
const Celebrate = ({winner}) => {
    return(
        <div className={"celebration " + (winner ? "celebrate" : "")}>
            <img className="confetti" src={Confetti} alt=""/>
            <h1 className="bob">Winner Winner <p>hire me please :)</p></h1>
        </div>
    );
}

export default Celebrate;