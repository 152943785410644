import React from "react";
import { Switch, Route } from "react-router-dom";
import Contact from "../Contact/Contact";
import ProjectsContainer from "../NewWebsite/Projects/Projects";
import SkillsContainer from "../NewWebsite/Skills/Skills";
import About from "../NewWebsite/About/About";
import Home from "../NewWebsite/Home/Home";

export default function Routing() {
  return (
    <div className="main">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/skills" component={SkillsContainer} />
        <Route exact path="/projects" component={ProjectsContainer} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </div>
  );
}
